import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  ThemeProvider,
  createMuiTheme,
  responsiveFontSizes,
} from '@material-ui/core'
import * as colors from '@material-ui/core/colors/'
import { StylesProvider, createGenerateClassName } from '@material-ui/core/styles'
import { useAppContext } from 'hooks/useAppContext'
import { randomString } from 'lib/utils'

const primaryColor = colors[process.env.REACT_APP_PRIMARY_COLOR]
const secondaryColor = colors[process.env.REACT_APP_SECONDARY_COLOR]

export const fontSizeMap = {
  sm: 11,
  md: 16,
  lg: 24,
}

const generateClassName = createGenerateClassName({
  productionPrefix: 'grg',
  seed: randomString(6),
})

const makeTheme = ({ type = 'light', fontSize = 'md' }) => {
  const size = fontSizeMap[fontSize] || fontSizeMap.md
  const theme = createMuiTheme({
    spacing: size / 2,
    typography: {
      fontSize: size,
    },
    palette: {
      type: ['light', 'dark'].includes(type) ? type : 'light',
      primary: {
        main: primaryColor[800],
      },
      secondary: {
        main: secondaryColor.A400,
      },
    },
  })

  const tabHeight = theme.spacing(6)

  theme.mixins.fullHeight = ({ property = 'minHeight', minusTabs = false } = {}) => {
    const styles = {
      [property]: `calc(100vh - ${
        minusTabs
        ? theme.mixins.toolbar.minHeight + tabHeight
        : theme.mixins.toolbar.minHeight
      }px)`,
    }

    for (const breakpoint in theme.mixins.toolbar) {
      if (breakpoint.indexOf('@media') === -1) {
        continue
      }

      styles[breakpoint] = {
        [property]: `calc(100vh - ${
          minusTabs
          ? theme.mixins.toolbar[breakpoint].minHeight + tabHeight
          : theme.mixins.toolbar[breakpoint].minHeight
        }px)`,
      }
    }

    return styles
  }

  theme.mixins.offsetHeader = (property = 'top', { offsetTabs = false } = {}) => {
    const additionalOffset = offsetTabs ? tabHeight : 0
    const styles = {
      [property]: theme.mixins.toolbar.minHeight + additionalOffset,
    }

    for (const breakpoint in theme.mixins.toolbar) {
      if (breakpoint.indexOf('@media') === -1) {
        continue
      }

      styles[breakpoint] = {
        [property]: theme.mixins.toolbar[breakpoint].minHeight + additionalOffset,
      }
    }

    return styles
  }

  theme.spacing.drawerWidth = theme.spacing(30)

  return responsiveFontSizes(theme)
}

const Provider = ({ children }) => {
  const { themeType, themeFontSize } = useAppContext()
  return useMemo(() => {
    const theme = makeTheme({ type: themeType, fontSize: themeFontSize })
    return (
      <StylesProvider generateClassName={generateClassName}>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </StylesProvider>
    )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [themeType, themeFontSize])
}

Provider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Provider
