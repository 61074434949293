
import { useSnackbar } from 'notistack'

export function useSetMessage () {
  const { enqueueSnackbar } = useSnackbar()
  const setMessage = (message, options = {}) => {
    if (message) {
      enqueueSnackbar(message, options)
    }
  }

  return setMessage
}
