import gql from 'graphql-tag.macro'
import logParams from 'graphql/fragments/logParams'

export default gql`
  mutation CloseDoor($doorKey: DoorKey) {
    closeDoor(key: $doorKey) {
      changed
      door {
        key
        name
        state {
          status
          open
          closed
        }
      }
      log {
        ...LogParams
      }
    }
  }
  ${logParams}
`
