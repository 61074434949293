import React, { useState } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Slide,
  Fade,
  List,
  Divider,
  Typography,
  Link,
  ListItem,
  ListItemText,
  useMediaQuery,
  useTheme,
  makeStyles,
} from '@material-ui/core'
import InfoButton from 'components/InfoButton'
import ListItemLink from 'components/ListItemLink'
import { useAppContext } from 'hooks/useAppContext'
import { version } from '../../package.json'
import { useGarageData } from 'hooks/useGarageData'

const revision = process.env.REACT_APP_GIT_COMMIT
const host = window.location.host
  .split('.').slice(-2).join('.').split(':').shift()

const useStyles = makeStyles(theme => ({
  infoButton: {
    color: theme.palette.primary.contrastText,
    fontSize: theme.spacing(2.5),
    marginLeft: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginBottom: '.2em',
    },
  },
}))

const VersionModalButton = () => {
  const classes = useStyles()
  const { installationName, timezone } = useGarageData()
  const { apiVersion } = useAppContext()
  const theme = useTheme()
  const fullScreen = !useMediaQuery(theme.breakpoints.up('sm'))

  const [isModalOpen, setIsModalOpen] = useState(false)

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <InfoButton className={classes.infoButton} onClick={openModal}>
        View more information about the Garage App
      </InfoButton>
      <Dialog
        open={isModalOpen}
        onClose={closeModal}
        scroll='paper'
        TransitionComponent={fullScreen ? Slide : Fade}
        fullScreen={fullScreen}
        fullWidth
        keepMounted
      >
        <DialogTitle>About Garage App</DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            This is a progressive web app, built
            in <Link color='secondary' href='https://reactjs.org/'>React</Link>,
            that enables you to monitor and control a garage door
            anywhere you have an internet connection.
          </Typography>
          <Divider />
          <List>
            <ListItem
              component={ListItemLink}
              href='https://twitter.com/pDroll'
              button
              divider
            >
              <ListItemText
                primary='pDroll'
                secondary='Built By'
              />
            </ListItem>
            {installationName && (
              <ListItem divider>
                <ListItemText
                  primary={installationName}
                  secondary='Installation'
                />
              </ListItem>
            )}
            <ListItem divider>
              <ListItemText
                primary={host}
                secondary='Host'
              />
            </ListItem>
            {timezone && (
              <ListItem divider>
                <ListItemText
                  primary={timezone}
                  secondary='Time zone'
                />
              </ListItem>
            )}
            <ListItem divider>
              <ListItemText
                primary={version}
                secondary='Version'
              />
            </ListItem>
            <ListItem divider>
              <ListItemText
                primary={revision}
                secondary='Revision'
              />
            </ListItem>
            {apiVersion.version && (
              <ListItem divider>
                <ListItemText
                  primary={apiVersion.version}
                  secondary='API Version'
                />
              </ListItem>
            )}
            {apiVersion.revision && (
              <ListItem>
                <ListItemText
                  primary={apiVersion.revision}
                  secondary='API Revision'
                />
              </ListItem>
            )}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color='secondary'>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default VersionModalButton
