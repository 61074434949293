import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { fade } from '@material-ui/core/styles/colorManipulator'
import { times } from 'lib/utils'
import classnames from 'classnames'
import CarIcon from 'icons/Car'
import RoofIcon from 'icons/Triangle'
import yellow from '@material-ui/core/colors/yellow'
import { VALID_STATUSES } from 'hooks/useDoorControl'

const useStyles = makeStyles(theme => {
  const frameColor = theme.palette.type === 'light'
    ? theme.palette.grey[400]
    : theme.palette.grey[700]

  const headlight = {
    transition: theme.transitions.create('opacity'),
    opacity: 1,
    zIndex: 1,
    position: 'absolute',
    borderRadius: '100%',
    height: '12.6%',
    width: '12.6%',
    top: '54%',
    background: yellow[200],
  }

  return {
    root: {
      width: '100%',
      textAlign: 'center',
      marginTop: '8vw',
      position: 'relative',
    },
    box: {
      position: 'relative',
      height: 'calc(100vh - 200px)',
      maxHeight: '45vh',
      width: '90%',
      margin: '0 auto',
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',

      [`@media (max-width: ${theme.breakpoints.values.sm}px) and (orientation: landscape)`]: {
        maxWidth: '40vw',
      },

      [theme.breakpoints.up('md')]: {
        maxWidth: theme.spacing(100),
      },
    },
    boxSquare: {
      height: 500,
      width: 625,
      maxHeight: 'none',
    },
    frame: {
      position: 'absolute',
      zIndex: 2,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderColor: frameColor,
      border: '5vh solid',
      borderTopWidth: '2vh',
      borderBottom: 0,
      pointerEvents: 'none',

    },
    roof: {
      position: 'absolute',
      bottom: '100%',
      marginBottom: -1,
      color: frameColor,
      width: 'calc(100% + 4px)',
    },
    doorWrapper: {
      transition: theme.transitions.create('opacity'),
      position: 'absolute',
      overflow: 'hidden',
      zIndex: 1,
      top: 'calc(2vh)',
      left: 'calc(5vh)',
      right: 'calc(5vh)',
      bottom: 0,
      pointerEvents: 'none',
    },
    door: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      padding: '1.25vw 1.25vw 0 1.25vw',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      background: theme.palette.background.default,
      transition: 'transform linear',
      transitionDuration: ({ isAnimating, delay }) => `${isAnimating ? delay : 0}s`,

      [theme.breakpoints.up('md')]: {
        padding: '0.5vw 0.5vw 0 0.5vw',
      },
    },
    pane: {
      background: fade(theme.palette.grey[600], 0.5),
      width: '100%',
      height: '32%',
      borderRadius: theme.shape.borderRadius,
    },
    doorOpen: {
      transform: 'translateY(-98%)',
    },
    car: {
      position: 'relative',
      transition: theme.transitions.create('opacity', { delay: '300ms' }),
      height: theme.spacing(37),
      width: theme.spacing(37),
      transform: 'translateY(12%)',

      [theme.breakpoints.up('md')]: {
        height: theme.spacing(40),
        width: theme.spacing(40),
      },

      [`@media (max-height: ${theme.breakpoints.values.sm}px)`]: {
        height: theme.spacing(20),
        width: theme.spacing(20),
      },
    },

    carIcon: {
      opacity: 0.6,
      position: 'relative',
      zIndex: 2,
      height: '100%',
      width: '100%',
    },
    leftHeadlight: {
      ...headlight,
      left: '20.8%',
    },
    rightHeadlight: {
      ...headlight,
      right: '20.8%',
    },
    hidden: {
      opacity: 0,
    },
  }
})

const DoorGraphic = ({ square, isAnimating, delay, status, className }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [lightsOn, setLightsOn] = useState(false)
  const classes = useStyles({ isAnimating, delay })

  // Wait a beat to update isOpen to ensure the
  // CSS transition change is always applied first
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsOpen([VALID_STATUSES.open, VALID_STATUSES.opening].includes(status))
    }, 16)

    return () => { clearTimeout(timeout) }
  }, [status])

  const rootClasses = [className, classes.root].join(' ')
  const boxClasses = classnames({
    [classes.box]: true,
    [classes.boxSquare]: square,
  })

  const doorClasses = classnames({
    [classes.door]: true,
    [classes.doorOpen]: isOpen,
  })

  const doorWrapperClasses = classnames({
    [classes.doorWrapper]: true,
    [classes.hidden]: !status,
  })

  const carClasses = classnames({
    [classes.car]: true,
    [classes.hidden]: !status,
  })

  const leftHeadlightClasses = classnames({
    [classes.leftHeadlight]: true,
    [classes.hidden]: !lightsOn,
  })

  const rightHeadlightClasses = classnames({
    [classes.rightHeadlight]: true,
    [classes.hidden]: !lightsOn,
  })

  const onCarClick = (e) => {
    if (e.target.nodeName.toLowerCase() === 'path') {
      setLightsOn(true)
      setTimeout(() => {
        setLightsOn(false)
        setTimeout(() => {
          setLightsOn(true)
          setTimeout(() => { setLightsOn(false) }, 300)
        }, 300)
      }, 300)
    }
  }

  return (
    <div className={rootClasses}>
      <div className={boxClasses}>
        <RoofIcon className={classes.roof} />
        <div className={classes.frame} />
        <div className={doorWrapperClasses}>
          <div data-testid='door' className={doorClasses}>
            {times(3, (i) => (<div key={i} className={classes.pane} />))}
          </div>
        </div>
        <div className={carClasses}>
          <CarIcon onClick={onCarClick} className={classes.carIcon} />
          <div className={leftHeadlightClasses} />
          <div className={rightHeadlightClasses} />
        </div>
      </div>
    </div>
  )
}

DoorGraphic.propTypes = {
  square: PropTypes.bool,
  isAnimating: PropTypes.bool,
  className: PropTypes.string,
  status: PropTypes.oneOf(Object.values(VALID_STATUSES)),
  delay: PropTypes.number.isRequired,
}

export default DoorGraphic
