import jwt from 'jsonwebtoken'
const { localStorage } = window

export const getToken = (type = 'access') => localStorage.getItem(`${type}Token`)
export const setToken = (token, type = 'access') => localStorage.setItem(`${type}Token`, token)
export const removeToken = (type = 'access') => localStorage.removeItem(`${type}Token`)

export const getTokenData = (type = 'access') => {
  const token = jwt.decode(getToken(type))

  let data = null
  if (token && token.exp > Math.floor(Date.now() / 1000)) {
    data = token

    delete data.iat
    delete data.exp
  } else {
    removeToken(type)
  }

  return data
}

export const tokenIsValid = (type = 'access') => {
  return !!getTokenData(type)
}

export default {
  getToken,
  setToken,
  removeToken,
  getTokenData,
  tokenIsValid,
}
