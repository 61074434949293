import { get } from 'lib/utils'
import getDoorLogs from 'graphql/queries/getDoorLogs'

const { btoa } = window

export const LOG_LIST_PAGE_SIZE = 25

export const encryptCursor = (logData) => btoa(logData.timestamp)

export const addLogOnMutation = (doorKey, logPath) => (cache, { data }) => {
  const logData = get(logPath, data)
  let doorData
  try {
    doorData = cache.readQuery({ query: getDoorLogs, variables: { doorKey } })
  } catch (e) {
    doorData = {
      door: {
        __typename: 'DoorType',
        key: doorKey,
        logList: makeEmptyRelayPagination(),
      },
    }
  }

  const newCursor = encryptCursor(logData)
  const existingEdges = doorData.door.logList?.edges || []

  const filteredExistingEdges = existingEdges.filter(
    edge => edge.node.id !== logData.id
  )

  const newData = {
    ...doorData,
    door: {
      ...doorData.door,
      logList: {
        ...doorData.door.logList,
        pageInfo: {
          ...doorData.door.pageInfo,
          count: filteredExistingEdges.length + 1,
        },
        edges: [
          {
            __typename: 'LogEdgeType',
            cursor: newCursor,
            node: {
              ...logData,
              __typename: 'LogType',
            },
          },
          ...filteredExistingEdges,
        ],
      },
    },
  }

  cache.writeQuery({
    query: getDoorLogs,
    variables: { doorKey },
    data: newData,
  })
}

export const makeEmptyRelayPagination = () => {
  return {
    edges: [],
    pageInfo: {
      __typename: 'PageInfoType',
      endCursor: undefined,
      hasPreviousPage: false,
      hasNextPage: true,
      count: 0,
    },
  }
}

export default {
  LOG_LIST_PAGE_SIZE,
  encryptCursor,
  addLogOnMutation,
  makeEmptyRelayPagination,
}
