import React from 'react'
import PropTypes from 'prop-types'
import { Avatar, makeStyles } from '@material-ui/core'
import classNames from 'classnames'

const useStyles = makeStyles(theme => ({
  avatar: {
    fontSize: theme.spacing(2),
    background: theme.palette.background.level1,
  },
}))

// eslint-disable-next-line camelcase
const UserAvatar = ({ first_name, firstName, last_name, lastName, username, avatar, className }) => {
  const classes = useStyles()
  const initials = firstName
  ? firstName.charAt(0) + lastName.charAt(0)
  : first_name.charAt(0) + last_name.charAt(0)

  const avatarClasses = classNames({
    [classes.avatar]: true,
    [className]: true,
  })

  return (
    <Avatar alt={username} className={avatarClasses} src={avatar}>
      {!avatar && initials.toUpperCase()}
    </Avatar>
  )
}

UserAvatar.propTypes = {
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  username: PropTypes.string.isRequired,
  avatar: PropTypes.string,
  className: PropTypes.string,
}

export default UserAvatar
