import React from 'react'
import Icon from 'icons/Icon'

const Triangle = (props) => {
  return (
    <Icon {...props} viewBox='0 0 20 2'>
      <polygon points='10, 0 20, 2 0, 2' />
    </Icon>
  )
}
export default Triangle
