import React from 'react'
import PropTypes from 'prop-types'
import { AppContextProvider } from 'hooks/useAppContext'
import { AuthProvider } from 'hooks/useAuth'
import { HelmetProvider } from 'react-helmet-async'
import SnackbarProvider from 'providers/SnackbarProvider'
import ThemeProvider from 'providers/ThemeProvider'
import ApolloProvider from 'providers/ApolloProvider'
import { SnackbarHeightProvider } from 'hooks/useSnackbarHeight'

const Providers = ({ children }) => (
  <AppContextProvider>
    <HelmetProvider>
      <ThemeProvider>
        <SnackbarHeightProvider>
          <SnackbarProvider>
            <AuthProvider>
              <ApolloProvider>
                {children}
              </ApolloProvider>
            </AuthProvider>
          </SnackbarProvider>
        </SnackbarHeightProvider>
      </ThemeProvider>
    </HelmetProvider>
  </AppContextProvider>
)

Providers.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Providers
