import React from 'react'
import PropTypes from 'prop-types'
import { IconButton, Typography } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/InfoTwoTone'

const InfoButton = ({ onClick = () => {}, children, ...props }) => {
  return (
    <IconButton onClick={onClick} size='small' {...props}>
      <InfoIcon fontSize='inherit' />
      {children && (
        <Typography variant='srOnly'>
          {children}
        </Typography>
      )}
    </IconButton>
  )
}

InfoButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.string,
}

export default InfoButton
