import { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { usePrevious } from 'hooks/usePrevious'
import openDoorMutation from 'graphql/mutations/openDoor'
import closeDoorMutation from 'graphql/mutations/closeDoor'
import geolocateLogMutation from 'graphql/mutations/geolocateLog'
import { VALID_STATUSES } from 'hooks/useDoorControl'
import { addLogOnMutation } from 'graphql/utils'

export function useDoorToggle ({ door, refetch, loading }) {
  const doorKey = door?.key
  const previousDoorKey = usePrevious(doorKey)
  const [hasToggled, setHasToggled] = useState(false)
  const [isToggling, setToggling] = useState(false)
  const [isClosing, setClosing] = useState(false)
  const [isOpening, setOpening] = useState(false)

  if (doorKey !== previousDoorKey && hasToggled) {
    setHasToggled(false)
  }

  const delay = door?.delay
  const state = door?.state
  const status = state?.status

  const [openDoor, { loading: doorOpenLoading, data: openDoorData }] = useMutation(
    openDoorMutation, { update: addLogOnMutation(doorKey, ['openDoor', 'log']) }
  )
  const [closeDoor, { loading: closeDoorLoading, data: closeDoorData }] = useMutation(
    closeDoorMutation, { update: addLogOnMutation(doorKey, ['closeDoor', 'log']) }
  )
  const [geolocateLog] = useMutation(
    geolocateLogMutation, { update: addLogOnMutation(doorKey, ['geolocateLog']) }
  )

  const isChanged = openDoorData?.openDoor?.changed || closeDoorData?.closeDoor?.changed

  useEffect(() => {
    let timeout

    // If the door was just opened or closed
    if (isChanged && hasToggled) {
      setToggling(true)
      clearTimeout(timeout)

      let toggleLogId
      if (state.closed) {
        toggleLogId = closeDoorData?.closeDoor?.log?.id
       } else {
        toggleLogId = openDoorData?.openDoor?.log?.id
       }

       if (toggleLogId && navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const { latitude, longitude, accuracy } = position.coords
              const variables = { id: toggleLogId, input: { latitude, longitude, accuracy } }
              geolocateLog({ variables })
            },
            err => console.log('Error getting position:', err),
            { enableHighAccuracy: true }
          )
       }

      // Set a timeout to refetch the door's state
      // after waiting for the door to finish moving
      timeout = setTimeout(() => {
        refetch()
        setToggling(false)
      }, delay * 1000)
    // Otherwise cleanup timeout
    } else {
      setToggling(false)
      clearTimeout(timeout)
    }

    // And cleanup after ourselves on the way out
    return () => {
      setToggling(false)
      clearTimeout(timeout)
    }
  }, [status, isChanged, delay, refetch, hasToggled, state, closeDoorData, openDoorData, geolocateLog])

  const variables = { doorKey }
  const toggleDoor = () => {
    setHasToggled(true)
    setToggling(false)

    if (state.closed) {
      setOpening(true)
      setClosing(false)
      return openDoor({ variables })
     }

    setClosing(true)
    setOpening(false)

    return closeDoor({ variables })
  }

  let currentStatus = state?.status
  if (isToggling && isOpening) { currentStatus = VALID_STATUSES.opening }
  if (isToggling && isClosing) { currentStatus = VALID_STATUSES.closing }

  const isLoading = loading || doorOpenLoading || closeDoorLoading || isToggling

  return {
    status: currentStatus,
    isLoading,
    isToggling,
    toggleDoor,
  }
}
