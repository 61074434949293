import React, { useState, useContext, createContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useMediaQuery } from '@material-ui/core'
const { localStorage } = window

function useProvideAppContext () {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const [prefersDarkModeCached, cachePrefersDarkMode] = useState(prefersDarkMode)

  const initialFontSize = localStorage.getItem('themeFontSize') || 'md'
  const initialThemeType =
    localStorage.getItem('themeType') ||
    (prefersDarkMode ? 'dark' : 'light')

  const [pageTitle, setPageTitle] = useState('')
  const [loading, setLoading] = useState(false)
  const [apiVersion, setApiVersion] = useState({ version: null, revision: null })
  const [themeFontSize, setThemeFontSize] = useState(initialFontSize)
  const [themeType, setThemeType] = useState(initialThemeType)

  if (prefersDarkMode !== prefersDarkModeCached) {
    setThemeType(initialThemeType)
    cachePrefersDarkMode(prefersDarkMode)
  }

  const changeThemeType = (preference) => {
    let color = preference
    if (!['light', 'dark'].includes(preference)) {
      console.error('Theme type can only be `light` or `dark`')
      color = 'light'
    }

    setThemeType(color)
    localStorage.setItem('themeType', color)
  }

  const changeThemeFontSize = (preference) => {
    let size = preference
    if (!['sm', 'md', 'lg'].includes(preference)) {
      console.error('Theme font size can only be `sm`, `md`, or `lg`')
      size = 'md'
    }

    setThemeFontSize(size)
    localStorage.setItem('themeFontSize', size)
  }

  return {
    pageTitle,
    setPageTitle,
    loading,
    apiVersion,
    setApiVersion,
    setLoading: state => setLoading(!!state),
    themeType,
    changeThemeType,
    themeFontSize,
    changeThemeFontSize,
  }
}

const appContext = createContext()
appContext.displayName = 'AppContext'

export function useAppContext () {
  return useContext(appContext)
};

export function useSetPageTitle (title) {
  const { setPageTitle } = useAppContext()
  useEffect(() => setPageTitle(title), [setPageTitle, title])
}

export function AppContextProvider ({ children }) {
  const context = useProvideAppContext()
  return <appContext.Provider value={context}>{children}</appContext.Provider>
}

AppContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
