import React from 'react'
import PropTypes from 'prop-types'
import {
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { Link } from '@reach/router'
import { useGarageData } from 'hooks/useGarageData'
import VersionModalButton from 'components/VersionModalButton'
import ListItemLink from 'components/ListItemLink'
import HomeIcon from '@material-ui/icons/HomeRounded'
import LogsIcon from '@material-ui/icons/FormatListBulletedRounded'
import TextIcon from '@material-ui/icons/TextsmsRounded'
import StatIcon from '@material-ui/icons/PieChartRounded'
import UsersIcon from '@material-ui/icons/GroupRounded'
import { useAuth } from 'hooks/useAuth'

const useStyles = makeStyles(theme => ({
  titleBox: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(4, 2),
    display: 'flex',
    alignItems: 'flex-end',
  },
  installation: {
    display: 'block',
    fontSize: '.6em',
  },
  title: {
    color: 'inherit',
    textDecoration: 'none',
  },
  drawer: {
    flexGrow: 0,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.drawerWidth,
      flexShrink: 0,
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: theme.spacing.drawerWidth,
  },
}))

const Nav = ({ isOpen, onClose }) => {
  const classes = useStyles()
  const { installationName, doors } = useGarageData()
  const { canAccessFeature } = useAuth()

  const drawerContent = (
    <>
      <div className={classes.titleBox}>
        <Link onClick={onClose} className={classes.title} to='/'>
          <Typography component='h2' variant='h5'>
            <small className={classes.installation}>
              {installationName}
              &nbsp;
            </small>
            Garage App
          </Typography>
        </Link>
        <VersionModalButton />
      </div>
      <List>
        <ListItem
          component={ListItemLink}
          onClick={onClose}
          to='/'
          button
          divider
        >
          <ListItemIcon><HomeIcon /></ListItemIcon>
          <ListItemText primary={doors.length > 1 ? 'Doors' : 'Door'} />
        </ListItem>

        {canAccessFeature('viewLogs') && (
          <ListItem
            component={ListItemLink}
            onClick={onClose}
            to='/logs'
            button
            divider
          >
            <ListItemIcon><LogsIcon /></ListItemIcon>
            <ListItemText primary='Logs' />
          </ListItem>
        )}

        {canAccessFeature('viewStats') && (
          <ListItem
            component={ListItemLink}
            onClick={onClose}
            to='/stats'
            button
            divider
          >
            <ListItemIcon><StatIcon /></ListItemIcon>
            <ListItemText primary='Usage Stats' />
          </ListItem>
        )}

        {canAccessFeature('editUsers') && (
          <ListItem
            component={ListItemLink}
            onClick={onClose}
            to='/users'
            button
            divider
          >
            <ListItemIcon><UsersIcon /></ListItemIcon>
            <ListItemText primary='Users' />
          </ListItem>
        )}
        <ListItem
          component={ListItemLink}
          onClick={onClose}
          to='/txt'
          button
        >
          <ListItemIcon><TextIcon /></ListItemIcon>
          <ListItemText primary='Text Messaging' />
        </ListItem>
      </List>
    </>
  )

  return (
    <nav className={classes.drawer}>
      <Hidden smUp implementation='js'>
        <Drawer
          variant='temporary'
          open={isOpen}
          onClose={onClose}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {drawerContent}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation='js'>
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant='permanent'
          open
        >
          {drawerContent}
        </Drawer>
      </Hidden>
    </nav>
  )
}

Nav.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default Nav
