import React from 'react'
import PropTypes from 'prop-types'
import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/CloseRounded'
import { SnackbarProvider } from 'notistack'
import { useSnackbarHeightContext } from 'hooks/useSnackbarHeight'

const snackbarRef = React.createRef()
const onClickCloseSnackbar = key => () => {
  snackbarRef.current.closeSnackbar(key)
}
const closeSnackbar = (key) => (
  <IconButton
    aria-label='Close this message'
    size='small'
    color='inherit'
    onClick={onClickCloseSnackbar(key)}
  >
    <CloseIcon />
  </IconButton>
)
const anchor = {
  vertical: 'bottom',
  horizontal: 'center',
}

const Provider = ({ children }) => {
  const { snackbarHeight, setSnackbarHeight } = useSnackbarHeightContext()

  return (
    <SnackbarProvider
      maxSnack={1}
      ref={snackbarRef}
      action={closeSnackbar}
      anchorOrigin={anchor}
      onEntering={(el) => setSnackbarHeight(snackbarHeight + el.offsetHeight)}
      onExiting={(el) => setSnackbarHeight(snackbarHeight - el.offsetHeight)}
      dense
      hideIconVariant
      preventDuplicate
    >
      {children}
    </SnackbarProvider>
  )
}

Provider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Provider
