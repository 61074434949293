import { useEffect } from 'react'
import { useLazyQuery, useApolloClient } from '@apollo/client'
const { localStorage } = window
// response signature, to be overridden as needed
const response = { error: null, loading: false, data: null }

// After fetching the data from the graphql server, the returned data will
// be cached in the apollo cache and saved in local storage.
// Subsequent calls will load the data from the cache.
// Subsequent page loads will parse the data from localStorage and
// store it in the apollo cache
export const useCachedQuery = (query, options = {}) => {
  const [fetchData, { data: freshData, error, loading }] = useLazyQuery(query, options)
  const apolloClient = useApolloClient()
  // Use the name of the query as the localStorage key
  let storageKey = query.definitions[0].name.value
  if (options.variables) {
    storageKey += JSON.stringify(options.variables)
  }

  // readQuery will raise an exception if the query is not in the cache.
  // https://www.apollographql.com/docs/react/features/caching/#readquery
  let cachedData = null
  try { cachedData = apolloClient.readQuery({ query }, true) } catch {}

  // only read from localStorage if the data is not in the cache
  let savedData = null
  if (!cachedData) { savedData = localStorage.getItem(storageKey) }

  // Note: the useEffect call has to happen unconditionally,
  // before any early returns
  useEffect(() => {
    // Only fetch the data from the server
    // if we don't already have it cached or saved
    if (
      !savedData &&
      !cachedData &&
      !loading &&
      !error
    ) { fetchData() }
  })

  // Save the data from the server for subsequent page loads
  if (freshData) { localStorage.setItem(storageKey, JSON.stringify(freshData)) }

  // Return data from the cache before even looking at localStorage
  if (cachedData) { return { ...response, data: cachedData } }

  // If the data isn't cached, parse and cache the data from localStorage
  if (savedData) {
    const parsedData = JSON.parse(savedData)
    // Save it to the cache for next time
    apolloClient.writeQuery({ query: query, data: parsedData })
    return { ...response, data: parsedData }
  }

  // If it's not in the cache and not in localStorage, return the
  // data, error, and loading states from the apollo query
  return { error, loading, data: freshData }
}
