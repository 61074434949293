import React from 'react'
import { Fab, CssBaseline, makeStyles } from '@material-ui/core'
import OpenIcon from '@material-ui/icons/ArrowUpwardRounded'
import DoorGraphic from 'components/DoorGraphic'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100vw',
    marginTop: '-4%',
  },
  button: {
    position: 'absolute',
    zIndex: 2,
    top: '50%',
    left: '50%',
    height: theme.spacing(50),
    width: theme.spacing(50),
    transform: 'translateX(-50%) translateY(-35%)',
  },
  icon: {
    height: theme.spacing(40),
    width: theme.spacing(40),
  },
}))

const DoorIcon = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <CssBaseline />
      <DoorGraphic
        square
        status='closed'
        isLoading={false}
        isToggling={false}
        delay={0}
      />
      <Fab className={classes.button} color='primary'>
        <OpenIcon className={classes.icon} />
      </Fab>
    </div>
  )
}

export default DoorIcon
