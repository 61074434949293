import { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { usePageVisibility } from 'hooks/usePageVisibility'
import { useDoorToggle } from 'hooks/useDoorToggle'
import { useAuth } from 'hooks/useAuth'
import getDoorState from 'graphql/queries/getDoorState'

const { EventSource } = window

export const VALID_STATUSES = Object.freeze({
  open: 'open',
  closed: 'closed',
  opening: 'opening',
  closing: 'closing',
})

export function useDoorControl ({ doorKey, accessToken }) {
  const { user } = useAuth()
  const userId = user.id
  // Get the current state of the door
  const { data, loading, refetch } = useQuery(getDoorState, {
    variables: { doorKey },
    fetchPolicy: 'network-only',
  })

  // Refetch the door's state when the page becomes visible
  usePageVisibility({ onShow: () => { refetch() } })

  // Refetch the door's state when receiving a `toggle` server sent event
  useEffect(() => {
    const eventSource = new EventSource(
      `${process.env.REACT_APP_API_BASE}v1/events?token=${accessToken}`
    )

    eventSource.addEventListener('toggle', (messageEvent) => {
      const logData = JSON.parse(messageEvent.data)
      const toggleUserId = logData?.user?.id
      if (userId !== toggleUserId) {
        refetch()
      }
    })

    return () => {
      eventSource.removeEventListener('toggle', refetch)
      eventSource.close()
    }
  }, [accessToken, refetch, userId])

  // Pass door data to the useDoorToggle hook
  const door = data?.door
  const {
    status,
    isLoading,
    isToggling,
    toggleDoor,
  } = useDoorToggle({ door, loading, refetch })

  return {
    status,
    isLoading,
    isToggling,
    toggleDoor,
  }
}
