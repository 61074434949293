import gql from 'graphql-tag.macro'
import logParams from 'graphql/fragments/logParams'

export default gql`
query GetDoorLogs($doorKey: DoorKey!, $after: String, $before: String, $count: Int) {
  door(key: $doorKey) {
    key
    logList(after: $after, before: $before, count: $count) {
      pageInfo {
        hasNextPage
        endCursor
        count
      }
      edges {
        cursor
        node {
          ...LogParams
        }
      }
    }
  }
}
${logParams}
`
