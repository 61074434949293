import React, { lazy, Suspense } from 'react'
import PropTypes from 'prop-types'
import Loading from 'components/Loading'

const AsyncComponent = (props) => {
  const { componentPath } = props
  const Component = lazy(() => import(`../${componentPath}`))

  const { loader, ...childProps } = props

  const Fallback = loader || Loading

  return (
    <Suspense fallback={<Fallback />}>
      <Component {...childProps} />
    </Suspense>
  )
}

AsyncComponent.propTypes = {
  componentPath: PropTypes.string.isRequired,
  loader: PropTypes.any,
}

export default AsyncComponent
