import gql from 'graphql-tag.macro'

export default gql`
  query GetDoorState($doorKey: DoorKey!) {
    door(key: $doorKey) {
      key
      name
      delay
      state {
        open
        closed
        status
      }
    }
  }
`
