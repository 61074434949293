import gql from 'graphql-tag.macro'

export default gql`
  query GetGarageData {
    installationName
    timezone
    doors {
      key
      delay
      name
      default
    }
  }
`
