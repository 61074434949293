import gql from 'graphql-tag.macro'

export default gql`
mutation GeolocateLog($id: ID!, $input: GeolocationInput! ) {
  geolocateLog(id: $id, input: $input) {
    id
    type
    locationProvided
    location {
      longitude
      latitude
      accuracy
    }
    state
    timestamp
  }
}

`
