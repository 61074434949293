import React from 'react'
import {
  Container,
  CircularProgress,
  Typography,
  makeStyles,
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  container: {
    ...theme.mixins.fullHeight(),
    display: 'flex',
  },
  spinner: {
    margin: 'auto',
    flexGrow: 0,
  },
}))

const Loading = () => {
  const classes = useStyles()
  return (
    <Container className={classes.container}>
      <CircularProgress thickness={2} color='secondary' className={classes.spinner} />
      <Typography variant='srOnly'>Loading...</Typography>
    </Container>
  )
}

export default Loading
