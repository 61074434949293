import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  ApolloProvider,
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  from,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { relayStylePagination } from '@apollo/client/utilities'
import { useAuth } from 'hooks/useAuth'
import { useAppContext } from 'hooks/useAppContext'
import { useSetMessage } from 'hooks/useSetMessage'
import { getVersionFromUrl } from 'lib/utils'
import { LOG_LIST_PAGE_SIZE, makeEmptyRelayPagination } from 'graphql/utils'
import getActiveDoor from 'graphql/queries/getActiveDoor'
import uniq from 'lodash/uniq'

const { localStorage } = window

const relayPagination = relayStylePagination()

const cache = new InMemoryCache({
  typePolicies: {
    DoorType: {
      keyFields: ['key'],
      fields: {
        logList: {
          ...relayPagination,
          merge: (existing, incoming, { args }) => {
            const merged = relayPagination.merge(existing, incoming, { args })
            // Do our best to remove duplicate edges
            return { ...merged, edges: uniq(merged.edges, 'cursor') }
          },
          read: (existing = makeEmptyRelayPagination()) => {
            const edges = existing?.edges || []
            return {
              edges,
              pageInfo: {
                ...existing.pageInfo,
                count: edges.length >= LOG_LIST_PAGE_SIZE ? edges.length : undefined,
                startCursor: edges[0]?.cursor || '',
                endCursor: edges[edges.length - 1]?.cursor || '',
              },
            }
          },
        },
      },
    },
  },
})

cache.writeQuery({
  query: getActiveDoor,
  data: { activeDoor: localStorage.getItem('activeDoor') },
})

const baseUrl = process.env.REACT_APP_API_BASE
const httpLink = new HttpLink({ uri: `${baseUrl}v2/graphql` })

const useAuthMiddleware = (tokenGetter) => {
  return setContext(async (request, headers) => {
    const token = await tokenGetter()
    return {
      headers: { Authorization: `Bearer ${token}` },
    }
  })
}

const useLoadingMiddleware = (loadingSetter) => (
  new ApolloLink((operation, forward) => {
    if (operation.operationName !== 'IntrospectionQuery') {
      loadingSetter(true)
    }
    return forward(operation).map(response => {
      loadingSetter(false)
      return response
    })
  })
)

const useVersionMiddleware = (versionSetter) => (
  new ApolloLink((operation, forward) => {
    return forward(operation).map(response => {
      const context = operation.getContext()
      const revision = context.response.headers.get('x-revision')
      const version = getVersionFromUrl(context.response.url)
      versionSetter({ revision, version })

      return response
    })
  })
)

const useErrorMiddleware = (loadingSetter, messageSetter) => (
  onError(({ graphQLErrors, networkError }) => {
    let errorMessage = 'An error occurred'
    if (networkError) {
      errorMessage =
      'We\'re having trouble contacting our server. Check your network connection and try again.'
      console.log(`[Network error]: ${errorMessage}`)
    }

    if (graphQLErrors) {
      graphQLErrors.forEach(({ message }) => {
        errorMessage = `${message}`
        console.log(`[GraphQL error]: Message: ${errorMessage}`)
      })
    }

    messageSetter(errorMessage, { variant: 'warning' })
    loadingSetter(false)
  })
)

const Provider = ({ children }) => {
  const { getAccessToken } = useAuth()
  const { setLoading, setApiVersion } = useAppContext()
  const setMessage = useSetMessage()
  const authMiddleware = useAuthMiddleware(getAccessToken)
  const loadingMiddleware = useLoadingMiddleware(setLoading)
  const versionMiddleware = useVersionMiddleware(setApiVersion)
  const errorMiddleware = useErrorMiddleware(setLoading, setMessage)
  const client = new ApolloClient({
    link: from([
      authMiddleware,
      loadingMiddleware,
      versionMiddleware,
      errorMiddleware,
      httpLink,
    ]),
    cache: cache,
    resolvers: {},
  })

  return useMemo(() => {
    return (<ApolloProvider client={client}>{children}</ApolloProvider>)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

Provider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Provider
