import React, { useState, useContext, createContext } from 'react'
import PropTypes from 'prop-types'

function useSnackbarHeight () {
  const [snackbarHeight, setSnackbarHeight] = useState(0)

  return {
    snackbarHeight,
    setSnackbarHeight,
  }
}

const snackbarHeightContext = createContext()
snackbarHeightContext.displayName = 'SnackbarHeightContext'

export function useSnackbarHeightContext () {
  return useContext(snackbarHeightContext)
};

export function SnackbarHeightProvider ({ children }) {
  const context = useSnackbarHeight()
  return (
    <snackbarHeightContext.Provider value={context}>
      {children}
    </snackbarHeightContext.Provider>
  )
}

SnackbarHeightProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
