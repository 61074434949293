import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { CssBaseline, Toolbar, useTheme, makeStyles } from '@material-ui/core'
import Header from './components/Header'
import Routes from './Routes'
import './App.css'

const useStyles = makeStyles({
  root: {
    background: theme => theme.palette.background.default,
    display: 'flex',
    justifyContent: 'center',
    height: '100vh',
    overflowY: 'auto',
    '-webkit-overflow-scrolling': 'touch',

  },
  main: {
    flexGrow: 1,
    position: 'relative',
  },
})

function App ({ isPreRender = false }) {
  const theme = useTheme()
  const classes = useStyles(theme)

  useEffect(() => {
    document.body.style.backgroundColor = theme.palette.primary.main
    document.querySelector('#root').style.backgroundColor = theme.palette.background.default
  }, [theme.palette])

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header />
      <main className={classes.main}>
        <Toolbar />
        {!isPreRender && <Routes />}
      </main>
    </div>
  )
}

App.propTypes = {
  isPreRender: PropTypes.bool,
}

export default App
