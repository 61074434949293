import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Link } from '@reach/router'

const ListItemLink = forwardRef((props, ref) => {
  if (props.to) {
    return <li ref={ref}><Link {...props}>{props.children}</Link></li>
  }
  return <li ref={ref}><a {...props}>{props.children}</a></li>
})

ListItemLink.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,
}

export default ListItemLink
