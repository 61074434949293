import React, { useEffect, useState, useContext, useCallback, createContext } from 'react'
import PropTypes from 'prop-types'
import { useSetMessage } from 'hooks/useSetMessage'
import { useApi } from 'hooks/useApi'
import { navigate } from '@reach/router'
import { getToken, getTokenData, tokenIsValid } from 'lib/tokens'
import { deepFreeze } from 'lib/utils'
const { localStorage, Image } = window

const roleFeatures = deepFreeze({
  user: ['toggleDoor'],
  auditor: ['toggleDoor', 'viewLogs', 'toggleNotifications', 'viewStats'],
  admin: ['toggleDoor', 'viewLogs', 'toggleNotifications', 'viewStats', 'editUsers'],
})

function useProvideAuth () {
  const [securityQuestion, setSecurityQuestion] = useState(null)
  const [accessToken, setAccessToken] = useState(null)
  const [isRefreshing, setIsRefreshing] = useState(false)
  const [user, setUser] = useState(null)
  const setMessage = useSetMessage()
  const api = useApi()

  const getSecurityQuestion = async () => {
    if (securityQuestion) { return securityQuestion }

    const question = await api.getSecurityQuestion()
    if (question) { setSecurityQuestion(question) }
    return question
  }

  const login = async ({ username, password, securityAnswer, remember }) => {
    const loggedInUser = await api.login({
      username, password, securityAnswer, remember,
    })

    if (loggedInUser) {
      localStorage.removeItem('updatedAvatar')
      setUser(loggedInUser)
      setMessage(`Welcome, ${loggedInUser.first_name}!`)
    }
  }

  const logout = async () => {
    await api.logout()
    localStorage.clear()
    setUser(null)
  }

  const forgetPassword = async ({ email, template }) => {
    const responseMessage = await api.forgetPassword({ email, template })

    if (responseMessage) {
      setMessage(responseMessage)
      navigate('/')
    }
  }

  const resetPassword = async ({ email, password, token }) => {
    const responseMessage = await api.resetPassword({ email, password, token })

    if (responseMessage) {
      setMessage(responseMessage)
      navigate('/')
    }
  }

  const refreshLogin = async () => {
    if (tokenIsValid('refresh')) {
      setMessage('Logging you in...')
      setIsRefreshing(true)
      const refreshedUser = await api.refreshLogin()
      const error = refreshedUser?.error
      setIsRefreshing(false)

      if (error) {
        setMessage('Please login again.', { variant: 'info' })
        return error.status === 401 ? logout() : null
      }

      if (refreshedUser) {
        localStorage.removeItem('updatedAvatar')
        setUser(refreshedUser)
        setMessage(`Welcome back, ${refreshedUser.first_name}!`)
        return refreshedUser
      }
    }
  }

  const getAccessToken = async () => {
    if (tokenIsValid()) {
      const token = getToken()
      setAccessToken(token)
      return token
    }

    await refreshLogin()
    const token = getToken()
    setAccessToken(token)
    return token
  }

  const editAvatar = async (avatar) => {
    if (!user) { return {} }
    const token = await getAccessToken()
    const updatedUser = await api.editUserAvatar({
      userId: user.id,
      avatar,
      token,
    })

    const newAvatar = updatedUser?.avatar

    if (!newAvatar) { return {} }

    // Force browser to download image
    // before updating the user object
    const image = new Image()
    image.src = newAvatar
    image.onload = () => setUser({ ...user, avatar: newAvatar })

    localStorage.setItem('updatedAvatar', newAvatar)

    return updatedUser
  }

  const canAccessFeature = useCallback((feature) => {
    if (!user) { return false }

    const role = user.role === 'super' ? 'admin' : user.role
    return !!roleFeatures?.[role]?.includes(feature)
  }, [user])

  useEffect(() => {
    const userData = getTokenData()
    if (userData) {
      const updatedAvatar = localStorage.getItem('updatedAvatar')
      if (updatedAvatar) {
        userData.avatar = updatedAvatar
      }

      setUser(userData)
    } else {
      refreshLogin()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Return the user object and auth methods
  return {
    getSecurityQuestion,
    securityQuestion,
    forgetPassword,
    resetPassword,
    login,
    logout,
    user,
    canAccessFeature,
    getAccessToken,
    isRefreshing,
    editAvatar,
    __accessToken: accessToken,
  }
}

const authContext = createContext()
authContext.displayName = 'AuthContext'

export const useAuth = () => {
  return useContext(authContext)
}

export function AuthProvider ({ children }) {
  const auth = useProvideAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
