import { useEffect, useState } from 'react'

export function getBrowserVisibilityProp () {
  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    return 'visibilitychange'
  } else if (typeof document.msHidden !== 'undefined') {
    return 'msvisibilitychange'
  } else if (typeof document.webkitHidden !== 'undefined') {
    return 'webkitvisibilitychange'
  }
}

export function getBrowserDocumentHiddenProp () {
  if (typeof document.hidden !== 'undefined') {
    return 'hidden'
  } else if (typeof document.msHidden !== 'undefined') {
    return 'msHidden'
  } else if (typeof document.webkitHidden !== 'undefined') {
    return 'webkitHidden'
  }
}

export function getIsDocumentVisible () {
  return !document[getBrowserDocumentHiddenProp()]
}

export function usePageVisibility ({ onShow = () => {}, onHide = () => {} } = {}) {
  const [isVisible, setIsVisible] = useState(getIsDocumentVisible())
  const onVisibilityChange = () => {
    const visible = getIsDocumentVisible()

    if (visible) { onShow() }
    if (!visible) { onHide() }

    return setIsVisible(visible)
  }

  useEffect(() => {
    const visibilityChange = getBrowserVisibilityProp()

    window.addEventListener(visibilityChange, onVisibilityChange, false)

    return () => {
      window.removeEventListener(visibilityChange, onVisibilityChange)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isVisible
}
