import React from 'react'
import { hydrate, render } from 'react-dom'
import App from 'App'
import IconPage from 'pages/IconPage'
import Rollbar from 'rollbar'
import * as serviceWorker from 'serviceWorker'
import Providers from 'providers/Providers'

const rollbarToken = process.env.REACT_APP_ROLLBAR_TOKEN
if (rollbarToken) {
  const config = {
    accessToken: rollbarToken,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: process.env.REACT_APP_ENV,
    },
  }

  window.Rollbar = new Rollbar(config)
}

const Child = process.env.REACT_APP_ICON_ONLY ? IconPage : App
const isPreRender = navigator.userAgent === 'PreRenderer'
const target = document.getElementById('root')
const renderer = target.hasChildNodes() ? hydrate : render

renderer(<Providers><Child isPreRender={isPreRender} /></Providers>, target)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
