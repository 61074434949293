import React, { useMemo } from 'react'
import { Router, Redirect } from '@reach/router'
import AsyncComponent from 'lib/AsyncComponent'
import { useAuth } from 'hooks/useAuth'
import Loading from 'components/Loading'

const Routes = () => {
  const { user, isRefreshing, canAccessFeature } = useAuth()

  return useMemo(() => {
    return (
      <>
        {!user && isRefreshing && (
          <Loading />
        )}
        {!user && !isRefreshing && (
          <Router>
            <AsyncComponent componentPath='pages/Forget' path='/forget' />
            <AsyncComponent componentPath='pages/Reset' path='/reset/:token' />
            <AsyncComponent componentPath='pages/Welcome' path='/welcome/:token' />
            <AsyncComponent componentPath='pages/Login' default />
          </Router>
        )}

        {user && (
          <Router>
            <Redirect noThrow from='/forget' to='/' />
            <Redirect noThrow from='/login' to='/' />
            <Redirect noThrow from='/reset/*' to='/' />
            <Redirect noThrow from='/welcome/*' to='/' />
            <AsyncComponent componentPath='pages/Home' path='/' />
            <AsyncComponent componentPath='pages/TextMessaging' path='/txt' />
            <AsyncComponent componentPath='pages/Profile' path='/profile' />
            <AsyncComponent componentPath='pages/Settings' path='/settings' />
            <AsyncComponent componentPath='pages/Logout' path='/logout' />
            {canAccessFeature('viewLogs') && (
              <AsyncComponent componentPath='pages/Logs' path='/logs' />
            )}
            {canAccessFeature('viewStats') && (
              <AsyncComponent componentPath='pages/Stats' path='/stats' />
            )}
            {canAccessFeature('editUsers') && (
              <>
                <AsyncComponent componentPath='pages/UserList' path='/users' />
                <AsyncComponent componentPath='pages/UserAdd' path='/users/new' />
                <AsyncComponent componentPath='pages/UserEdit' path='/users/:id' />
              </>
            )}
            <AsyncComponent componentPath='pages/NotFound' default />
          </Router>
        )}
      </>
    )
  }, [user, isRefreshing, canAccessFeature])
}

export default Routes
