import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  svg: {
    fill: 'currentColor',
  },
})

const Icon = (props) => {
  const classes = useStyles()
  const className = [props.className, classes.svg].join(' ')

  return (
    <svg role='img' {...props} className={className} />
  )
}

Icon.propTypes = {
  className: PropTypes.string,
  viewBox: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default Icon
