import gql from 'graphql-tag.macro'

export default gql`
fragment LogParams on LogType {
  id
  type
  state
  viaText
  locationProvided
  timestamp
  location {
    longitude
    latitude
    accuracy
  }
  user {
    id
    avatar
    username
    firstName
    lastName
  }
  door {
    key
  }
}
`
