import parseIso from 'date-fns/parseISO'
import formatRelative from 'date-fns/formatRelative'
import enLocale from 'date-fns/locale/en-US'
import format from 'date-fns/format'

export const baseApiUrl = process.env.REACT_APP_API_BASE

export const get = (p, o) =>
  p.reduce((xs, x) => (xs && xs[x]) ? xs[x] : null, o)

const baseUrl = process.env.REACT_APP_API_BASE
export const getVersionFromUrl = (url) =>
  url.replace(baseUrl, '').split('/')[0]

export const formatPhoneNumber = (s) => {
  const s2 = (String(s)).replace(/\D/g, '')
  const m = s2.match(/^1?(\d{3})(\d{3})(\d{4})$/)
  return (!m) ? null : `(${m[1]}) ${m[2]}-${m[3]}`
}

export const stateToAction = state => `${state.replace(/ed/g, '')}ed`

export const times = (n, iterator) => {
  const arr = Array(Math.max(0, n))
  for (var i = 0; i < n; i++) arr[i] = iterator(i)
  return arr
}

export const nonBreakingSpace = '\u00a0'

export const formatDateRelative = (date) => {
  const formatRelativeLocale = {
    lastWeek: "'Last' eeee '('P')'",
    yesterday: "'Yesterday ('P')'",
    today: "'Today ('P')'",
    other: 'P',
  }

  const locale = {
    ...enLocale,
    formatRelative: token => formatRelativeLocale[token],
  }

  return formatRelative(parseIso(date), new Date(), { locale })
}

export const randomString = (length) => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
     result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const deepFreeze = (obj) => {
  Object.getOwnPropertyNames(obj).forEach((name) => {
    const value = obj[name]

    obj[name] = value && typeof value === 'object'
      ? deepFreeze(value) : value
  })

  return Object.freeze(obj)
}

export const formatIso =
  (date, displayFormat = 'MMMM do yyyy h:mm a') => format(parseIso(date), displayFormat)
