import axios from 'axios'
import { get, getVersionFromUrl, baseApiUrl } from 'lib/utils'
import { useAppContext } from 'hooks/useAppContext'
import { useSetMessage } from 'hooks/useSetMessage'
import { setToken, getToken, removeToken, getTokenData } from 'lib/tokens'

const { FormData } = window

export const useApi = () => {
  const { setLoading, setApiVersion } = useAppContext()
  const setMessage = useSetMessage()

  const api = axios.create({
    baseURL: baseApiUrl,
    responseType: 'json',
  })

  api.interceptors.response.use((response) => {
    const { accesstoken, refreshtoken } = response.headers
    const revision = response.headers['x-revision']
    if (accesstoken) { setToken(accesstoken) }
    if (refreshtoken) { setToken(refreshtoken, 'refresh') }

    const version = getVersionFromUrl(response.config.url)

    setApiVersion({ revision, version })
    return response
  }, (error) => {
    const responseMessage = get(['response', 'data', 'message'], error)
    const errorMessage = responseMessage ||
      'We\'re having trouble contacting our server. Check your network connection and try again.'
    setMessage(errorMessage, { variant: 'warning' })

    if (error?.response?.status === 401) {
      return { error: { status: 401, message: 'unauthorized' } }
    }
  })

  const sendRequest = async (request) => {
    setLoading(true)

    let response
    try {
      response = await request()
    } finally {
      setLoading(false)
    }

    const status = get(['status'], response)

    if (response?.error) { return response }

    if (status === 200) {
      return response
    }

    return null
  }

  async function getSecurityQuestion () {
    const response = await sendRequest(() =>
      api.get('v1/authenticate/question')
    )

    if (response) {
      return response.data.question
    }

    return null
  }

  async function forgetPassword ({ email, template }) {
    const response = await sendRequest(() =>
      api.post('v1/password/forget', { email, template })
    )

    if (response) {
      return response.data.message
    }

    return null
  }

  async function resetPassword ({ email, password, token }) {
    const response = await sendRequest(() =>
      api.post('v1/password/reset', { email, password }, {
        headers: { Authorization: `Bearer ${token}` },
      })
    )

    if (response) {
      return response.data.message
    }

    return null
  }

  async function login ({ username, password, securityAnswer, remember }) {
    const response = await sendRequest(() =>
      api.post('v1/authenticate', {
        username, password, remember, security_answer: securityAnswer,
      })
    )

    if (response) {
      return getTokenData()
    }

    return null
  }

  async function refreshLogin () {
    const refreshToken = getToken('refresh')
    const response = await sendRequest(() =>
      api.post('v1/authenticate/refresh', {}, {
        headers: { Authorization: `Bearer ${refreshToken}` },
      })
    )

    if (response?.error) { return response }

    if (response) {
      return getTokenData()
    }

    return null
  }

  async function editUserAvatar ({ userId, avatar, token }) {
    const formData = new FormData()
    formData.append('avatar', avatar)

    const response = await sendRequest(() =>
      api.put(`v1/users/${userId}`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
    )

    const editedUser = response?.data?.user

    return editedUser || null
  }

  async function logout () {
    removeToken()
    removeToken('refresh')
    return true
  }

  return {
    getSecurityQuestion,
    forgetPassword,
    resetPassword,
    login,
    logout,
    refreshLogin,
    editUserAvatar,
  }
}
