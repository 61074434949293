import React, { useState } from 'react'
import { useAppContext } from 'hooks/useAppContext'
import { useAuth } from 'hooks/useAuth'
import {
  AppBar,
  Toolbar,
  Typography,
  LinearProgress,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  useTheme,
  makeStyles,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/MenuRounded'
import { Helmet } from 'react-helmet-async'
import Nav from 'components/Nav'
import UserAvatar from 'components/UserAvatar'
import ListItemLink from 'components/ListItemLink'

const useStyles = makeStyles(theme => ({
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${theme.spacing.drawerWidth}px)`,
      marginLeft: theme.spacing.drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  title: {
    flexGrow: 1,
  },
  profileMenuItem: {
    minWidth: theme.spacing(20),
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.divider,
  },
}))

const Header = () => {
  const [navOpen, setNavOpen] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const { pageTitle, loading } = useAppContext()
  const { user } = useAuth()
  const theme = useTheme()
  const classes = useStyles()

  const openNav = () => {
    setNavOpen(true)
  }

  const closeNav = () => {
    setNavOpen(false)
  }

  const openMenu = e => {
    setAnchorEl(e.currentTarget)
  }

  const closeMenu = () => {
    setAnchorEl(null)
  }

  const menuOpen = !!anchorEl

  const title = pageTitle ? `${pageTitle} | Garage App` : 'Garage App'

  return (
    <>
      <Helmet>
        <meta name='theme-color' content={theme.palette.primary.main} />
        <title>{title}</title>
      </Helmet>
      <AppBar className={user && classes.appBar} position='fixed'>
        <Toolbar>
          <noscript>JavaScript is required to use this app.</noscript>
          {user && (
            <IconButton
              color='inherit'
              aria-label='open drawer'
              edge='start'
              className={classes.menuButton}
              onClick={openNav}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography component='h1' variant='h6' className={classes.title}>
            {pageTitle}
          </Typography>
          {user && (
            <>
              <IconButton
                size='small'
                color='inherit'
                aria-label='Click to edit profile or logout'
                onClick={openMenu}
                edge='end'
              >
                <UserAvatar {...user} />
              </IconButton>
              <Menu
                keepMounted
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={menuOpen}
                onClose={closeMenu}
              >
                <MenuItem
                  dense
                  component={ListItemLink} to='/profile' onClick={closeMenu}
                  className={classes.profileMenuItem}
                >
                  <ListItemIcon>
                    <UserAvatar {...user} avatar={null} />
                  </ListItemIcon>
                  <ListItemText
                    primary={user.username}
                    secondary={
                      user.role.substr(0, 1).toUpperCase() +
                      user.role.substr(1)
                    }
                  />
                </MenuItem>
                <MenuItem component={ListItemLink} to='/profile' onClick={closeMenu}>
                  <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                    Edit Profile
                  </ListItemText>
                </MenuItem>
                <MenuItem component={ListItemLink} to='/settings' onClick={closeMenu}>
                  <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                    Settings
                  </ListItemText>
                </MenuItem>
                <MenuItem component={ListItemLink} to='/logout' onClick={closeMenu}>
                  <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                    Logout
                  </ListItemText>
                </MenuItem>
              </Menu>
            </>
          )}
        </Toolbar>
        {loading && <LinearProgress color='primary' mode='query' />}
      </AppBar>
      {user && (<Nav isOpen={navOpen} onClose={closeNav} />)}
    </>
  )
}

export default Header
